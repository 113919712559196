import HELPERS from "../utils/helper";

// const baseURL = `https://dev-api.cinchy.me/api/v1/`;
const baseURL = `https://staging-api.cinchy.me/api/v1/`;
// const baseURL = `https://prod-api.cinchy.me/api/v1/`;
const API_MANAGER = {
  login: (data) => {
    // const form_data = HELPERS.converToFormData(data);
    return HELPERS.request({
      baseURL,
      url: "recruiter-auth/sign-in",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  forgotPassword: (data) => {
    const form_data = HELPERS.converToFormData(data);

    return HELPERS.request({
      baseURL,
      url: "auth/forgot-password",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  verifyOtp: (data) => {
    const form_data = HELPERS.converToFormData(data);

    return HELPERS.request({
      baseURL,
      url: "auth/verify-otp",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  verifyLoginOtp: (data, token) => {
    return HELPERS.request({
      baseURL,
      url: "user/verify/last-login/otp",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
  lastLoginOtp: (token) => {
    return HELPERS.request({
      baseURL,
      url: "user/last-login/otp",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
  signUp: (data) => {
    const form_data = HELPERS.converToFormData(data);

    return HELPERS.request({
      baseURL,
      url: "recruiter-auth/sign-up",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  createNewPassword: (data, token) => {
    return HELPERS.request({
      baseURL,
      url: "user/change-password",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
  verifyEmail: (token) => {
    return HELPERS.request({
      baseURL,
      url: "user/verify-email",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
  verifyEmailOtp: (otp, token) => {
    return HELPERS.request({
      baseURL,
      url: "user/verify-email-otp",
      method: "POST",
      data: { otp: otp },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getSubscriptionData: () => {
    return HELPERS.secureRequest({
      baseURL,
      url: "subscription-product/find",
      data: {
        s_type: "Client",
      },
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  upgradeSubscription: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "subscription/upgrade/invoice",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  postSelectPlan: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "subscription/on-boarding/select-plan",
      data: data,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  postAdditionalUsers: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "subscription/on-boarding/additional-user",
      data: data,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getPaymentHistory: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "subscription/user-subscription",
      method: "GET",
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getUserData: (params) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "recruiter/get",
      params: params,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getNewsFeed: () => {
    return HELPERS.secureRequest({
      baseURL,
      url: `newsfeed?limit=12`,
      method: "GET",
      // params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getNewsDetail: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `newsfeed/${id}`,
      method: "GET",
      // params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  deleteUser: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `user/delete-recruiter-user/${id}`,
      method: "DELETE",
      // params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  updateUserPermission: (data, id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `recruiter-auth/update-permission/${id}`,
      method: "PATCH",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  updateUserpassword: (data, accessToken) => {
    return HELPERS.request({
      baseURL,
      url: "/user/update-password",
      data: data,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
  getCampaignListRoot: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `campaign/list`,
      method: "GET",
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getCampaignList: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `user/campaigns/list`,
      method: "GET",
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  campaignHeaderRoot: () => {
    return HELPERS.secureRequest({
      baseURL,
      url: `campaign/campaign-header`,
      method: "GET",
      // params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  campaignHeader: () => {
    return HELPERS.secureRequest({
      baseURL,
      url: `user/campaign-header`,
      method: "GET",
      // params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  activeUser: () => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/user/count`,
      method: "GET",
      // params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  autoRenew: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `subscription/toggle-auto-renew`,
      method: "PATCH",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getCampaignDetail: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `campaign/${id}`,
      method: "GET",
      // params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getCampaignAdverts: (id, data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `advert/campaignAdverts/${id ? id : ""}`,
      method: "GET",
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  createRecruiterProfile: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "recruiter/create",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  updateRecruiterProfile: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "recruiter/update",
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  addSubscription: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "subscription/buy-recruiter",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  deleteAdvert: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `advert/${id}`,
      method: "DELETE",
      // params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  deleteCampaign: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `campaign/${id}`,
      method: "DELETE",
      // params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  campaignArchive: (id, data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `campaign/${id}`,
      method: "PATCH",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // advertArchive: (id, data) => {
  //   return HELPERS.secureRequest({
  //     baseURL,
  //     url: `advert/${id}`,
  //     method: "PATCH",
  //     data: data,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   });
  // },
  bulkAdvertDelete: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `advert/bulk-delete`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  bulkCampaignDelete: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `campaign/bulk-delete`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  changeAdvertStatus: (id, data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/advert/status/${id}`,
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  userStatusToggle: (id, data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/user/update-status/${id}`,
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getTags: (type) => {
    return HELPERS.request({
      baseURL,
      url: `/tags?limit=2000&type=${type}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getTagsBySearch: (params) => {
    return HELPERS.request({
      baseURL,
      url: `/tags`,
      method: "GET",
      params: params,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  createCampaign: (data) => {
    return HELPERS.secureRequest({
      baseURL,

      url: `/campaign            `,
      method: "POST",

      url: `user/create-campaign`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  createCampaignRoot: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/campaign`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  updateCampaign_management: (data, id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `campaign/${id}`,
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  createAdvert: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/advert`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  updateAdvert: (id, data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/advert/${id}`,
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  resendVerificationLink: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `auth/resend-verification-link`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getAboutusData: () => {
    return HELPERS.request({
      baseURL,
      url: `/about`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getTermsConditionData: () => {
    return HELPERS.request({
      baseURL,
      url: `/terms-conditions`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getPrivacyPolicyData: () => {
    return HELPERS.request({
      baseURL,
      url: `/policy`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getAdvertDetail: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/advert/${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getAdvertCandidatesDetail: (id, data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/application/applied-jobs/${id}`,
      method: "GET",
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getAdvertsAssociate: (data, search, page) => {
    console.log("Dataaaa", data);
    return HELPERS.secureRequest({
      baseURL,
      url: `user/advert/lists?limit=10&page=${data?.page}&sort=${
        data?.sortType ? data?.sortType : ""
      }${data?.search ? `&search=${data?.search}` : ""}`,
      method: "POST",
      data: data?.param,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  acceptRejectCandidate: (data, id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/application/is/approved/${id}`,
      method: "PATCH",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  bulkAcceptRejectCandidate: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `application/bulk/approved`,
      method: "PATCH",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getTagsOptions: (tagName) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `tags?type=${tagName}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  updateCandidateJobStatus: (id, data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/application/offer/${id}`,
      method: "PATCH",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  updateBulkCandidateJobStatus: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/application/bulk-offer`,
      method: "PATCH",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  acceptShortlistOffer: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/application/accept-shortlist`,
      method: "PATCH",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getUsersList: (param) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/recruiter-auth/users`,
      method: "GET",
      params: param,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  createUser: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/recruiter-auth/user-sign-up`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // userEditCampaigns: (data) => {
  //   return HELPERS.secureRequest({
  //     baseURL,
  //     url: `/user/campaigns/list`,
  //     method: "GET",
  //     params: data,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   });
  // },
  updateCampaign: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/user/campaigns/update`,
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getRecruiterUserList: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/user/recruiter/userlist`,
      method: "GET",
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getSingleUserData: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `user/user/${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getTrackerDetail: (id, params) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/application/tracker/${id}`,
      params: params,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  addNewUser: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `subscription/buy-additional-recruiter-user`,
      data: data,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  postContactusData: (data) => {
    return HELPERS.request({
      baseURL,
      url: `/contact/contact-recruiter`,
      data: data,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getSingleUserCampaignDetails: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `campaign/advert-for-global/list`,
      method: "GET",
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getSingleUserCampaign: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `user/user-advert`,
      method: "GET",
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getSingleUserAdvertList: (data, page, search) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `user/advert/lists?limit=10&page=${page}&sort=-1${
        search ? `&search=${search}` : ""
      }`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  updateUserPersonalDetails: (id, data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `recruiter-auth/user/${id}`,
      method: "PATCH",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  updateCompanyDetails: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/recruiter/update`,
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  setYourPassword: (data, token) => {
    const form_data = HELPERS.converToFormData(data);

    return HELPERS.request({
      baseURL,
      url: "auth/verify-user",
      method: "PATCH",
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getUserDetail: (token) => {
    return HELPERS.request({
      baseURL,
      url: "auth/user",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getAppliedCandidateDetails: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `user/${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getArchiveList: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `campaign/list/`,
      method: "GET",
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  bulkArchive: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/campaign/bulk-unarchive`,
      method: "PATCH",
      data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  likeNews: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/newsfeed/likes/${id}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  updateTrackerNotes: (data, id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/application/${id}`,
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  fileUpload: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "file/upload-url",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  shareNews: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "user/share-news",
      data: data,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getNotification: () => {
    return HELPERS.secureRequest({
      baseURL,
      url: "notification/recruiter",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  readNotification: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `notification/recruiter/${id}`,
      method: "POST",
      // data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getCampaignReport: () => {
    return HELPERS.secureRequest({
      baseURL,
      url: "campaign/campaign-report",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getGlobalSearch: (params) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `candidate/candidate-global-search`,
      method: "GET",
      params: params,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getGlobalDetailSearch: (params) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `candidate/candidate-global-view-all`,
      method: "GET",
      params: params,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getAdvertReport: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `advert/advert-report/${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getCandidateReport: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `application/candidate-report/${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getTrackerReport: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `application/tracker-report/${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  transferApplication: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "application/transfer",
      data: data,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  inviteUsers: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "invite-candidate/invite-to-adverts",
      data: data,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  bulkApplicationDelete: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "application/delete-bulk-application",
      data: data,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  getSwitchUserList: () => {
    return HELPERS.secureRequest({
      baseURL,
      url: "/user-recruiters",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  getDownloadDocs: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `details/download-details/${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  acceptRejectInvite: (invitationId, data, token) => {
    return HELPERS.request({
      baseURL,
      url: `/invite-recruiter/status/${invitationId}`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getLocalData: () => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/recruiter-auth/user/token/time`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  discountCode: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/subscription/discount`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  ehHrReport: (params) => {
    const queryString = new URLSearchParams(params).toString();
    return HELPERS.secureRequest({
      baseURL,
      url: `/application/eh-hr-report/?${queryString}`,
      method: "GET",
      responseType: "blob",
      // headers: {
      //   "Content-Type": "application/json",
      // },
    });
  },
};

export default API_MANAGER;
